<template>
  <div>
    <loader v-if="loading"></loader>

    <!-- ADD QUEUE -->
    <!-- <overlay-form
      :overlay-title="'Добавить тип оборудования'"
      :form-type="'add'"
      :modal-state="addTypeModal"
      @close-modal="addTypeModal = false"
      @submit-button="addNewStackType()"
    >
      <v-form>
        <v-text-field v-model.trim="input.name" label="Полное название" />
        <v-text-field
          v-model.trim="input.codeName"
          label="Кодовое наименование"
        />
      </v-form>
    </overlay-form> -->

    <!-- BODY -->
    <v-container fluid>
      <v-card flat :dark="$dark.get()" class="stack-system__container">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-left">Id параметра</th>
                <th></th>
                <th class="text-left">Категория</th>
                <th class="text-left">Id категории</th>
                <th class="text-left">Очередность</th>
              </tr>
            </thead>
            <draggable
              v-model="categoryList"
              tag="tbody"
              v-bind="dragOptions"
              transition
              @start="drag = true"
              @end="drag = false"
            >
              <tr
                v-for="parameter in categoryList"
                :key="parameter.category.id"
              >
                <td>
                  <v-icon>mdi-menu</v-icon>
                </td>
                <td>{{ parameter.propertyId }}</td>
                <td>
                  <v-img
                    width="35px"
                    height="35px"
                    class="rounded-pill inline"
                    :src="
                      parameter.category.categoryImages
                        ? parameter.category.categoryImages.image.fileUrl
                        : ''
                    "
                  />
                </td>
                <td>{{ parameter.category.name }}</td>
                <td>{{ parameter.category.id }}</td>
                <td>{{ parameter.position }}</td>
                <td>
                  <v-btn
                    icon
                    @click="
                      showDeleteDialog = true;
                      selectedParameter = parameter;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>

    <v-btn class="pl-5 pr-5" color="#A4A4A4" @click="$router.go(-1)">
      Вернуться
    </v-btn>

    <!-- DELETE DIALOG -->
    <delete-dialog
      :show="showDeleteDialog"
      @close-modal="showDeleteDialog = false"
      @delete-modal="deleteParameter()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loader from "../components/Loader";
import OverlayForm from "../components/stackSystem/OverlayForm.vue";
import DeleteDialog from "@/components/DeleteDialog";
import draggable from "vuedraggable";

export default {
  name: "ExcludedQueue",
  components: {
    loader,
    // OverlayForm,
    DeleteDialog,
    draggable,
  },
  data() {
    return {
      loading: true,
      showDeleteDialog: false,
      drag: false,
      selectedParameter: {},
    };
  },
  computed: {
    ...mapGetters({
      EXCLUDED_QUEUE: "StackSystem/EXCLUDED_QUEUE",
    }),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    categoryList: {
      get() {
        return this.EXCLUDED_QUEUE;
      },
      async set(newValue) {
        await this.changeCategoryOrder(newValue);
        await this.renderPage();
      },
    },
  },
  async created() {
    await this.renderPage();
  },
  methods: {
    ...mapActions({
      GET_EXCLUDED_PARAMS: "StackSystem/GET_EXCLUDED_PARAMS",
      ADD_CATEGORY_INTO_EXCLUDED_QUEUE:
        "StackSystem/ADD_CATEGORY_INTO_EXCLUDED_QUEUE",
      EDIT_CATEGORY_ORDER_INTO_EXCLUDED_QUEUE:
        "StackSystem/EDIT_CATEGORY_ORDER_INTO_EXCLUDED_QUEUE",
      DELETE_EXCLUDED_PARAMETER: "StackSystem/DELETE_EXCLUDED_PARAMETER",
    }),
    async renderPage() {
      this.loading = true;
      await this.GET_EXCLUDED_PARAMS();
      this.loading = false;
    },
    // пока этот запрос не может быть реализован без новой api
    async changeCategoryOrder(arr) {
      this.loading = true;
      let newArr = this.serializeQueue(arr);
      await this.EDIT_CATEGORY_ORDER_INTO_EXCLUDED_QUEUE(newArr);
      await this.renderPage();
      this.loading = false;
    },
    serializeQueue(array) {
      return array.map((item, index) => {
        const { category, ...rest } = item;
        rest.position = index + 1;
        return rest;
      });
    },
    // пока этот запрос не может быть реализован без новой api
    async addNewCategory() {
      this.loading = true;
      // await this.ADD_CATEGORY_INTO_EXCLUDED_QUEUE();
      await this.renderPage();
      this.loading = false;
    },
    // пока этот запрос не может быть реализован без новой api
    async deleteParameter() {
      this.loading = true;
      await this.DELETE_EXCLUDED_PARAMETER(this.selectedParameter.propertyId);
      this.showDeleteDialog = false;
      await this.renderPage();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.inline {
  display: inline-block;
}
</style>
